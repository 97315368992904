/* eslint-disable @typescript-eslint/no-explicit-any */

import type {
  ApolloQueryResult,
  DocumentNode,
  FetchResult,
  NormalizedCacheObject,
} from '@apollo/client'
import type { TadaDocumentNode } from 'gql.tada'

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import Cookies from 'universal-cookie'

import { REFRESH_TOKEN } from 'Apollo/Queries'

import { notifyGraphqlError } from 'utils/functional'

const cookies = new Cookies()

const _API_URL = `${import.meta.env.VITE_API_URL}/graphql`
const brainUrl = _API_URL + '/brain'
const authUrl = _API_URL + '/auth'

let authClient: ApolloClient<NormalizedCacheObject> | undefined
let brainClient: ApolloClient<NormalizedCacheObject> | undefined

function createApolloClient(URL: string): ApolloClient<NormalizedCacheObject> {
  const httpLink = createHttpLink({ uri: URL })

  const authLink = setContext((_, { headers }) => {
    const token = cookies.get('auth_token')
    const Headers = {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      Accept: 'application/json',
    }

    return {
      headers: {
        ...Headers,
      },
    }
  })

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })
}

export function getAuthApolloClient(): ApolloClient<NormalizedCacheObject> {
  if (authClient) return authClient
  else authClient = createApolloClient(authUrl)

  return authClient
}

export function getBrainApolloClient(): ApolloClient<NormalizedCacheObject> {
  if (brainClient) return brainClient
  else brainClient = createApolloClient(brainUrl)

  return brainClient
}

export async function refreshToken(): Promise<string | null | undefined> {
  console.info('revalidando token...')

  let newToken: string | null | undefined

  await getBrainApolloClient()
    .query({ query: REFRESH_TOKEN })
    .then((res) => {
      newToken = res.data?.RefreshTokenQuery?.token
    })
    .catch(() => console.info('token inválido'))

  return newToken
}

export async function fetchApi<T>(
  query: TadaDocumentNode<T, any>,
  variables?: any,
): Promise<void | ApolloQueryResult<T>> {
  return getBrainApolloClient()
    .query<T>({
      query,
      variables,
      fetchPolicy: 'no-cache',
    })
    .catch(notifyGraphqlError)
}

export async function sendMutationToApi<T>(
  mutation: DocumentNode,
  variables?: any,
): Promise<FetchResult<T>> {
  return getBrainApolloClient().mutate<T>({
    mutation,
    variables,
  })
}

let apolloImage: ApolloClient<NormalizedCacheObject> | undefined

export async function sendFileToApi<T>(
  mutation: TadaDocumentNode<T, any>,
  variables?: any,
): Promise<FetchResult<T>> {
  if (!apolloImage) {
    const token = cookies.get('auth_token')
    const httpLink = createUploadLink({ uri: brainUrl })

    const authLink = setContext((_, { headers }) => {
      const Headers = {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        Accept: 'application/json',
      }

      return {
        headers: {
          ...Headers,
        },
      }
    })

    apolloImage = new ApolloClient({
      cache: new InMemoryCache(),
      link: authLink.concat(httpLink),
    })
  }

  return apolloImage.mutate<T>({
    mutation: mutation,
    variables: variables,
  })
}
